import * as React from "react";
import madeForMatrix from "../images/made_for_matrix.svg";
import ExternalLink from "../images/vector/external-link.svg";
import Conversation from "../images/vector/direct-messaging.svg";
import ChannelConversation from "../images/vector/channel-conversation.svg";
import SharedChannel from "../images/vector/shared-channel.svg";
//import BbcExternalLink from "../images/svg/bbc-external-link.svg";
import SignButtons from "./sign-buttons.js";
import Screenshot from "./screenshot/screenshot.js";

const BulletsPanel = ({ pageTitle, children }) => {
  return (
    <div className="c-bullets-container">
      <div className="c-bullets-container__content">
        <h1>Take a tour</h1>
        <div className="c-bullets-container__screenshot">
          <Screenshot
            title="hi"
            content="hi"
            colourIndx={1}
            same={false}
            disabled={false}
          ></Screenshot>
        </div>
        <div className="c-bullets-container__bullets">
          <div className="c-bullet">
            <div className="c-bullet__channel-icon">
              <ChannelConversation></ChannelConversation>
            </div>
            <h3>Channels</h3>
            <p>
              Conversations happen inside Channels which are centered around a
              particular team, topic or project. They keep conversations focused
              and provide an organized history of all your messages.
            </p>
          </div>
          <div className="c-bullet">
            <div className="c-bullet__shared-channel-icon">
              <SharedChannel></SharedChannel>
            </div>
            <h3>Shared channels</h3>
            <p>
              Shared channels enable collaboration with external partners.
              They're just like regular channels except they can include people
              from outside your organization.
            </p>
          </div>
          <div className="c-bullet">
            <div className="c-bullet__direct-message-icon">
              <Conversation></Conversation>
            </div>
            <h3>Direct messages</h3>
            <p>
              Direct Messages are used for private conversations between small
              groups of individuals. People can't be added to the conversation
              once it's started. Twadel allows you to message people from inside
              and outside your organization.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulletsPanel;
