/*
import * as React from "react";
import { Link } from "gatsby";
//import chatScreen from "../images/another.png";
import chatScreen from "../images/RWzif1.webp";
import SignButtons from "./sign-buttons.js";

const ChatPanel = ({ pageTitle, children }) => {
  return (
    <main className="c-chat-container">
      <div className="c-chat-container__content">
        <div className="c-chat-container__content__text">
          <h1>Twadel team chat</h1>
          <p>
            Start chatting with teammates, customers and vendors across multiple
            organizations as easily as starting an email conversation.
          </p>
          <SignButtons></SignButtons>
        </div>
      </div>
    </main>
  );
};

export default ChatPanel;
*/

import * as React from "react";
import { Link } from "gatsby";
//import chatScreen from "../images/vector/46b2132c01604c9493d558de444929f4.svg";
import chatScreen from "../images/ORIGINAL-smartmockups_kp4i9bqd.png";
import SignButtons from "./sign-buttons.js";
import Screenshot from "./screenshot/screenshot.js";

const ChatPanel = ({ pageTitle, children }) => {
  return (
    <main className="c-chat-container">
      <div className="c-chat-container__content">
        <div className="c-chat-container__content__text">
          <h1>Twadel team chat</h1>
          <p>
            Start chatting with teammates, customers and vendors from inside and
            outside your organization as easily as starting an email
            conversation.
          </p>
          <SignButtons></SignButtons>
        </div>
        <div className="c-chat-container__poster">
          <Screenshot
            title="hi"
            content="hi"
            colourIndx={1}
            same={false}
            disabled={true}
          ></Screenshot>
        </div>

        {/*
        <video
          className="c-chat-container__poster"
          ariaDescribedby="bring-your-team-together-in-channels-video"
          autoplay=""
          loop=""
          muted=""
          playsinline=""
          poster="https://a.slack-edge.com/9b527/marketing/img/homepage/hp-prospect/channels/image/bring-your-team-together-in-channels.en-GB.jpg"
        >
          <source
            src="https://a.slack-edge.com/9b527/marketing/img/homepage/hp-prospect/channels/mp4/bring-your-team-together-in-channels.en-GB.mp4"
            type="video/mp4"
          />
          <source
            src="https://a.slack-edge.com/5ebc9a2/marketing/img/homepage/hp-prospect/channels/webm/bring-your-team-together-in-channels.en-GB.webm"
            type="video/webm"
          />
        </video>
*/}
      </div>
    </main>
  );
};

export default ChatPanel;

/*
<img
          className="c-chat-container__content__pic"
          src="https://discord.com/assets/46b2132c01604c9493d558de444929f4.svg"
          alt="twadel-chat"
        />

*/
