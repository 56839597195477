import * as React from "react";
import madeForMatrix from "../images/made_for_matrix_inverted.svg";
import ExternalLink from "../images/vector/external-link.svg";
//import IconNetworkTopology from "../images/vector/icon_network-topology.svg";
import MatrixDiagram from "../images/vector/matrix.svg";
//import BbcExternalLink from "../images/svg/bbc-external-link.svg";
import SignButtons from "./sign-buttons.js";

const MatrixPanel = ({ pageTitle, children }) => {
  return (
    <main className="c-matrix-container">
      <div className="c-matrix-container__content">
        <div className="c-matrix-main" id="matrix">
          <div className="c-matrix-main__text">
            <h1>Open communication</h1>

            <p>
              Twadel is made for Matrix, which means you can chat with anyone
              with a Matrix ID regardless of which provider they choose.
            </p>

            <p>
              Governed by the{" "}
              <a href="https://matrix.org">
                Matrix.org Foundation <ExternalLink></ExternalLink>
              </a>
              , the Matrix protocol is an open standard for secure,
              decentralized communication.
            </p>

            <p>
              A rapidly growing community of over 30 million users across 60k
              deployments use Matrix.
            </p>

            <img src={madeForMatrix} alt="Made for matrix" />
          </div>
          <div className="c-matrix-main__pic">
            <MatrixDiagram></MatrixDiagram>
            {/*
            <img
              src="https://assets.website-files.com/5f6294c0c7a8cdd643b1c820/5fcff1a16213f9d33a6db5ff_ezgif.com-resize.gif"
              alt=""
              class="image-2"
            />
            */}
          </div>
        </div>
      </div>
    </main>
  );
};

export default MatrixPanel;
