import * as React from "react";
import MessageHandling from "./message-handling.js";
import ChannelList from "./channel-list.js";

const { useState } = React;

const Screenshot = ({ disabled }) => {
  const [selectedChannel, setSelectedChannel] = useState(0);

  const channelNames = [
    "general",
    "finance",
    "project-rebrand",
    "marketing-campaign",
    "sergio",
    "@hija:ocsiden.com",
    "ali, sergio",
  ];
  //setSelectedChannel("Engine21");
  return (
    <div className="c-screenshot">
      <div className="c-title-bar">
        <div className="c-title-bar__traffic-lights">
          <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" fill="#ff3535ff" />
          </svg>
          <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" fill="#ff8900ff" />
          </svg>
          <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" fill="#00c200ff" />
          </svg>
        </div>
      </div>
      <div className="c-screenshot-main">
        <div className="c-info">
          <p className="c-info__company">super-corp.com</p>
          <p className="c-info__person">@monica:super-corp.com</p>
        </div>
        <div className="c-screenshot-main__header">
          {channelNames[selectedChannel]}
        </div>
        <div className="c-screenshot-main__channel-list">
          <ChannelList
            selectedChannel={selectedChannel}
            onButtonClick={(selectedChannel) => {
              setSelectedChannel(selectedChannel);
              return;
            }}
            disabled={disabled}
          ></ChannelList>
        </div>
        {
          <div className="c-screenshot-main__message-handling">
            <MessageHandling
              selectedChannel={selectedChannel}
              disabled={disabled}
            ></MessageHandling>
          </div>
        }
      </div>
    </div>
  );
};

export default Screenshot;
