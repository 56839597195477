import * as React from "react";
import { Link } from "gatsby";
import sideLogo from "../images/side-logo.svg";
import SignButtons from "./sign-buttons.js";
/*
import {
  container,
  signUpInContainer,
  signUpInButton,
  signUpButton,
  signInButton,
  logo,
} from "./header.module.scss";
*/

//import "./header.module.scss";

const Header = ({ pageTitle, children }) => {
  return (
    <div className="c-header-container">
      <div className="c-header-container__content">
        <Link to="/">
          <img className="logo" src={sideLogo} alt="twadel-logo" />
        </Link>

        <div className="c-header-container__content__sign-buttons">
          <SignButtons></SignButtons>
        </div>
      </div>
    </div>
  );
};

export default Header;
