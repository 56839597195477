import * as React from "react";
import sideLogo from "../images/side-logo.svg";
import { Link } from "gatsby";

const Footer = ({ pageTitle, children }) => {
  return (
    <main className="c-footer">
      <div className="c-footer__content">
        <Link to="/">
          <img className="logo" src={sideLogo} alt="twadel-logo" />
        </Link>
        <p>Copyright &copy; 2021 Twadel</p>
      </div>
    </main>
  );
};

export default Footer;
