import * as React from "react";
import chatScreen from "../images/another.png";
import SignButtons from "./sign-buttons.js";

const EpiloguePanel = ({ pageTitle, children }) => {
  return (
    <main className="c-epilogue-container">
      <div className="c-epilogue-container__content">
        <h1>Transform how your organization communicates</h1>
        <SignButtons></SignButtons>
      </div>
    </main>
  );
};

export default EpiloguePanel;

/*
<div className="c-chat-container__content__text">
          <h1>Twadel team chat</h1>
          <p>
            Start chatting with teammates, customers and vendors across multiple
            organizations as easily as starting an email conversation.
          </p>
          <SignButtons></SignButtons>
        </div>
        <img
          className="c-chat-container__content__pic"
          src={chatScreen}
          alt="twadel-chat"
        />
        */
