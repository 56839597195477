import * as React from "react";
import stockImage from "../../images/christina-wocintechchat-com-fjza4oBV8ic-unsplash.jpg";

const colours = [
  "rgba(197, 8, 126, 255)",
  "rgba(227, 207, 18, 255)",
  "rgba(28, 181, 105, 255)",
  "rgba(35, 188, 224, 255)",
  "rgba(116, 49, 196, 255)",
  "rgba(197, 8, 126, 255)",
  "rgba(197, 19, 19, 255)",
  "rgba(250, 134, 6, 255)",
  "rgba([227, 207, 18, 255)",
  "rgba(123, 201, 71, 255)",
  "rgba(28, 181, 105, 255)",
  "rgba(35, 188, 224, 255)",
  "rgba(116, 49, 196, 255)",
  "rgba(197, 8, 126, 255)",
  "rgba(197, 19, 19, 255)",
  "rgba(250, 134, 6, 255)",
  "rgba(227, 207, 18, 255)",
  "rgba(123, 201, 71, 255)",
  "rgba(28, 181, 105, 255)",
  "rgba(35, 188, 224, 255)",
  "rgba(116, 49, 196, 255)",
  "rgba(197, 8, 126, 255)",
  "rgba(197, 19, 19, 255)",
  "rgba(250, 134, 6, 255)",
  "rgba(227, 207, 18, 255)",
  "rgba(123, 201, 71, 255)",
];

function makeAvatar(matrixID, colourIndx) {
  let colour = colours[colourIndx];
  return (
    <svg
      //width="27"
      //height="27"
      fill={colour}
      version="1.1"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m-3-3h24v24h-24z" fill="none" />
      <path d="m0 2v14c0 1.1 0.89 2 2 2h14c1.1 0 2-0.9 2-2v-14c0-1.1-0.9-2-2-2h-14c-1.11 0-2 0.9-2 2zm12 4c0 1.66-1.34 3-3 3s-3-1.34-3-3 1.34-3 3-3 3 1.34 3 3zm-9 8c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1h-12z" />

      <path
        d="m 12 6 c0 1.66-1.34 3-3 3s-3-1.34-3-3 1.34-3 3-3 3 1.34 3 3zm-9 8c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1h-12z"
        fill="white"
      />
    </svg>
  );
}

const Message = ({
  title,
  timestamp,
  content,
  colourIndx,
  same,
  image,
  enabled,
}) => {
  return same ? (
    <div
      className={
        "c-message  c-message--no-avatar c-message__text " +
        (enabled ? "c-message--enabled" : "")
      }
    >
      <div className="c-message__timestamp--no-avatar">{timestamp}</div>
      <p>{content}</p>
    </div>
  ) : (
    <div className={"c-message " + (enabled ? "c-message--enabled" : "")}>
      <div className="c-message__avatar">{makeAvatar(title, colourIndx)}</div>

      <div className="c-message__text">
        <div className="c-message__sender">{title}</div>
        <div className="c-message__timestamp">{timestamp}</div>

        <p>{content}</p>

        {image ? (
          <img
            className="c-message__pic"
            src={stockImage}
            alt="Example of adding an image to a Twadel chat"
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Message;
