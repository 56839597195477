import * as React from "react";
import Message from "./message.js";
import sendMessageIcon from "../../images/send-black-24dp.svg";
import attachFileIcon from "../../images/attach_file-black-24dp.svg";

const MessageHandling = ({ selectedChannel, disabled }) => {
  return (
    <div className="c-message-handling">
      <div
        className={
          "c-message-list " +
          (disabled ? "c-message-list--disabled" : "c-message-list--enabled")
        }
      >
        {getMessages(selectedChannel, !disabled)}
      </div>
      <div className="c-message-input">
        <div className="send-message">
          <div className="form-group">
            <textarea
              disabled
              rows="1"
              className="form-control"
              placeholder="Reply"
            ></textarea>
          </div>

          <button disabled type="submit" className="send-message-button">
            <img src={sendMessageIcon} alt="send-message" />
          </button>

          <button disabled type="button" className="attach-file-button">
            <img src={attachFileIcon} alt="attach-file" />
          </button>
          {selectedChannel === 0 ? (
            <div className="typing-notification">Svetlana is typing...</div>
          ) : (
            <div className="typing-notification"></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessageHandling;

const getMessages = (index, enabled) => {
  if (index === 0) {
    return (
      <div>
        <div className="c-date-splitter">
          <div className="c-date-splitter__line"></div>
          <div className="c-date-splitter__bubble">{"Yesterday"}</div>
        </div>
        <Message
          title="Monica Smith"
          timestamp="12:34"
          content="Hey everyone! Welcome to super-corp"
          colourIndx={4}
          same={false}
          enabled={enabled}
        ></Message>
        <Message
          title="Ali Rice"
          timestamp="12:36"
          content="Great to be here!"
          colourIndx={2}
          same={false}
          enabled={enabled}
        ></Message>
        <Message
          title="Monica Smith"
          timestamp="12:38"
          content="All hands meeting Today at 14:30"
          colourIndx={4}
          same={false}
          enabled={enabled}
        ></Message>
        <Message
          title="Sergio Benitez"
          timestamp="12:40"
          content="Where will the meeting be held?"
          colourIndx={1}
          same={false}
          enabled={enabled}
        ></Message>
        <Message
          title="Monica Smith"
          timestamp="12:40"
          content="Oh, forgot to say! In meeting room A"
          colourIndx={4}
          same={false}
          enabled={enabled}
        ></Message>
        <Message
          title="Monica Smith"
          timestamp="12:40"
          content="Next to the coffee machine"
          colourIndx={4}
          same={true}
          enabled={enabled}
        ></Message>
        <div className="c-date-splitter">
          <div className="c-date-splitter__line"></div>
          <div className="c-date-splitter__bubble">{"Today"}</div>
        </div>
        <Message
          title="Svetlana Asghar"
          timestamp="10:34"
          content="Thanks for the meeting yesterday - very useful"
          colourIndx={3}
          same={false}
          enabled={enabled}
        ></Message>
        <Message
          title="Monica Smith"
          timestamp="10:34"
          content="Glad you found it useful. We'll be having a 
          regular meeting at the same time and place each week. I've sent an invitation round"
          colourIndx={4}
          same={false}
          enabled={enabled}
        ></Message>
        <Message
          title="Sven Abebe"
          timestamp="12:34"
          content="Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus,
          repudiandae quas nisi quidem excepturi, numquam vero quasi
          cupiditate architecto recusandae voluptatum temporibus eius, magni
          fuga ut! Cumque nisi debitis ut."
          colourIndx={5}
          same={false}
          enabled={enabled}
        ></Message>
        <Message
          title="Sven Abebe"
          timestamp="12:34"
          content="Hey everyone! Just testing."
          colourIndx={5}
          same={true}
          enabled={enabled}
        ></Message>
        <Message
          title="Monica Smith"
          timestamp="12:36"
          content="Well done"
          colourIndx={4}
          same={false}
          enabled={enabled}
        ></Message>
        <Message
          title="Ali Rice"
          timestamp="12:48"
          content="Lunch time!"
          colourIndx={2}
          same={false}
          enabled={enabled}
        ></Message>
        <Message
          title="Monica Smith"
          timestamp="14:10"
          content=""
          colourIndx={4}
          same={false}
          image={true}
          enabled={enabled}
        ></Message>
        <Message
          title="Monica Smith"
          timestamp="14:10"
          content="I'm working from home this afternoon"
          colourIndx={4}
          same={false}
          enabled={enabled}
        ></Message>
        <Message
          title="Monica Smith"
          timestamp="14:10"
          content="I'll be back in the office Tomorrow. I'm available all day on Twadel."
          colourIndx={4}
          same={true}
          enabled={enabled}
        ></Message>
        <Message
          title="Sven Abebe"
          timestamp="14:12"
          content="OK, thanks Monica"
          colourIndx={5}
          same={false}
          enabled={enabled}
        ></Message>
      </div>
    );
  } else if (index === 1) {
    return (
      <div>
        <Message
          title="Monica Smith"
          timestamp="08:34"
          content="Good morning!"
          colourIndx={4}
          same={false}
          enabled={enabled}
        ></Message>
        <Message
          title="Monica Smith"
          timestamp="08:34"
          content="The financial audit is next week. We  should use this week to run through the
          remaining tasks."
          colourIndx={4}
          same={true}
          enabled={enabled}
        ></Message>
        <Message
          title="Sven Abebe"
          timestamp="09:01"
          content="Got it! I'm working through the list you shared"
          colourIndx={5}
          same={false}
          enabled={enabled}
        ></Message>
      </div>
    );
  } else if (index === 2) {
    return (
      <div>
        <Message
          title="Sergio Benitez"
          timestamp="15:25"
          content="I've started a shared channel to work with our colleagues over at Ocsiden rebranding"
          colourIndx={1}
          same={false}
          enabled={enabled}
        ></Message>
        <Message
          title="Sergio Benitez"
          timestamp="15:26"
          content="Feel free to add any relevant people"
          colourIndx={1}
          same={true}
          enabled={enabled}
        ></Message>
        <Message
          title="@hija:ocsiden.com"
          timestamp="16:59"
          content="Hey everyone, great working with you guys"
          colourIndx={5}
          same={false}
          enabled={enabled}
        ></Message>
      </div>
    );
  } else if (index === 3) {
    return (
      <div>
        <Message
          title="Sergio Benitez"
          timestamp="15:25"
          content="I've started a shared channel to work with our colleagues over at Ocsiden marketing"
          colourIndx={1}
          same={false}
          enabled={enabled}
        ></Message>
        <Message
          title="Sergio Benitez"
          timestamp="15:26"
          content="Feel free to add any relevant people"
          colourIndx={1}
          same={true}
          enabled={enabled}
        ></Message>
        <Message
          title="@hija:ocsiden.com"
          timestamp="16:59"
          content="Hey everyone, great working with you guys"
          colourIndx={5}
          same={false}
          enabled={enabled}
        ></Message>
      </div>
    );
  } else if (index === 4) {
    return (
      <div>
        <Message
          title="Sergio Benitez"
          timestamp="15:25"
          content="Hi Monica"
          colourIndx={1}
          same={false}
          enabled={enabled}
        ></Message>
        <Message
          title="Sergio Benitez"
          timestamp="15:26"
          content="Are we still on for my annual review next Wednesday?"
          colourIndx={1}
          same={true}
          enabled={enabled}
        ></Message>
        <Message
          title="Monica Smith"
          timestamp="16:59"
          content="yes, should be interesting!"
          colourIndx={4}
          same={false}
          enabled={enabled}
        ></Message>
      </div>
    );
  } else if (index === 5) {
    return (
      <div>
        <Message
          title="Monica Smith"
          timestamp="15:25"
          content="Hi Hija"
          colourIndx={4}
          same={false}
          enabled={enabled}
        ></Message>
        <Message
          title="Monica Smith"
          timestamp="15:26"
          content="How is your company getting on with my marketing team?"
          colourIndx={1}
          same={true}
          enabled={enabled}
        ></Message>
        <Message
          title="@hija:ocsiden.com"
          timestamp="16:59"
          content="great, we're making great progress"
          colourIndx={5}
          same={false}
          enabled={enabled}
        ></Message>
      </div>
    );
  } else if (index === 6) {
    return (
      <div>
        <Message
          title="Monica Smith"
          timestamp="15:25"
          content="Hi guys"
          colourIndx={4}
          same={false}
          enabled={enabled}
        ></Message>
        <Message
          title="Monica Smith"
          timestamp="15:26"
          content="Would either of you be able to meet Mr Itsuki tomorrow morning?"
          colourIndx={1}
          same={true}
          enabled={enabled}
        ></Message>
        <Message
          title="Ali Rice"
          timestamp="16:59"
          content="I can do it"
          colourIndx={2}
          same={false}
          enabled={enabled}
        ></Message>
      </div>
    );
  }
};
