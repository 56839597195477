import * as React from "react";
//import businessCard from "../images/christina-wocintechchat-com-fjza4oBV8ic-unsplash.jpg";
//import Message from "./message.js";
import ChannelConversation from "../images/vector/channel-conversation.svg";
import ExternalLink from "../images/vector/external-link.svg";
//import BusinessCard from "../images/vector/business-card.svg";
import BusinessCard from "./business-card";

const BusinessCardPanel = ({ pageTitle, children }) => {
  return (
    <div className="c-business-card-container">
      <div className="c-business-card-container__content">
        <h1>Chat without boundaries</h1>
        <div className="c-step-container">
          <div class="c-step-container__step">
            <div>
              <h3>Organized conversations</h3>
              <p>
                Twadel brings your company communications together into well
                organized channels.
              </p>
            </div>
          </div>
          <div class="c-step-container__step">
            <div>
              <h3>Contact addresses</h3>
              <p>
                Each member of your organization is given a unique identifier
                which looks a bit like an email address. We call this a Matrix
                ID.
              </p>
            </div>
          </div>
          <div class="c-step-container__step">
            <div>
              <h3>External collaborators</h3>
              <p>
                As easily as starting an email conversation, use Matrix IDs to
                start chatting with groups of colleagues from inside and outside
                your organization.
              </p>
            </div>
          </div>
          <div class="c-step-container__step">
            <div>
              <h3>Open communication</h3>
              <p>
                Your external partners don't even have to be signed up to
                Twadel, provided they have a Matrix ID you can chat with them
                regardless of which provider they choose.
              </p>
            </div>
          </div>
        </div>
        <div className="c-business-card-container__hand-both">
          <div className="c-business-card-container__hand">
            <BusinessCard></BusinessCard>
            {/*
          <svg
            version="1.1"
            baseProfile="full"
            width="300"
            height="200"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="100%" height="100%" fill="black" />
            <circle cx="150" cy="100" r="90" fill="blue" />
          </svg>
          */}
          </div>
          <div className="c-business-card-container__hand2">
            <BusinessCard></BusinessCard>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessCardPanel;

/*

<div className="c-business-card-container__text">
          <div className="display-messages">
            <div>
              <Message
                title="Twadel Bot"
                content="Like Slack and Microsoft Teams, Twadel brings your company
                         communications together into well organized channels."
                colourIndx={3}
              ></Message>

              <Message
                title="Twadel Bot"
                content="With Twadel each member of your team has a unique
                        identifier which looks a bit like an email address. We call this a
                        Matrix ID."
                colourIndx={2}
                same={true}
              ></Message>

              <Message
                title="Twadel Bot"
                content="As easily as you would start an email conversation, use Matrix IDs
                to start chatting with groups of colleagues from inside and outside your organization.
                "
                colourIndx={3}
              ></Message>

              <Message
                title="Twadel Bot"
                content="They don't even have to be signed up
                to Twadel, provided they have a Matrix ID you can chat away regardless of
                which provider they choose."
                colourIndx={2}
                same={true}
              ></Message>

              <Message
                title="Twadel Bot"
                content="So share your Matrix ID and start chatting with partners and vendors from
                external organizations in well organized, free flowing channels."
                colourIndx={2}
                same={true}
              ></Message>
            </div>
          </div>
        </div>
        <img
          className="c-business-card-container__pic"
          src={businessCard}
          alt="business-card"
        />

*/

//=============

/*
<div className="c-business-card-container__content__text">
          <p>
            Just like Slack and Microsoft Teams, Twadel brings your company
            communication together into well organized channels.
          </p>

          <p>
            With Twadel however, each member of your team has a unique
            identifier which looks a bit like an email address. We call this a
            Matrix ID.
          </p>

          <p>
            In the same way you can email anyone, anywhere, you can use matrix
            IDs to chat with anyone, anywhere. The people you're messaging don't
            even have to be signed up to Twadel, any Matrix provider will do.
          </p>

          <p>
            Whereas now we share email addresses and work in long email threads,
            Twadel allows people to share Matrix IDs and chat with people from
            external organizations in well organized, free flowing channels.
          </p>
        </div>



        <img
          className="c-business-card-container__content__pic"
          src={businessCard}
          alt="business-card"
        />
        */
