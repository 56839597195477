import * as React from "react";

export function lock() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="rgb(224, 222, 222)"
        class="padlock"
        d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9m3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"
      />
    </svg>
  );
}

const ChannelList = ({ selectedChannel, onButtonClick, disabled }) => {
  return (
    <div className="c-channel-list">
      <div>
        <p className="c-channel-list__type-header">Channels</p>
        <button
          disabled={disabled}
          className={
            "c-channel-list-entry " +
            (selectedChannel === 0 ? "c-channel-list-entry--selected" : "")
          }
          onClick={() => {
            onButtonClick(0);
          }}
        >
          <div className="c-channel-list-entry__icon">#</div> general
        </button>
        <button
          disabled={disabled}
          className={
            "c-channel-list-entry " +
            (selectedChannel === 1 ? "c-channel-list-entry--selected" : "")
          }
          onClick={() => onButtonClick(1)}
        >
          <div className="c-channel-list-entry__icon">{lock()}</div>
          finance
        </button>
      </div>
      <div>
        <p className="c-channel-list__type-header">Shared channels</p>
        <button
          disabled={disabled}
          className={
            "c-channel-list-entry " +
            (selectedChannel === 2 ? "c-channel-list-entry--selected" : "")
          }
          onClick={() => onButtonClick(2)}
        >
          <div className="c-channel-list-entry__icon">{lock()}</div>{" "}
          project-rebrand
        </button>
        <button
          disabled={disabled}
          className={
            "c-channel-list-entry " +
            (selectedChannel === 3 ? "c-channel-list-entry--selected" : "")
          }
          onClick={() => onButtonClick(3)}
        >
          <div className="c-channel-list-entry__icon">{lock()}</div>
          marketing-campaign
        </button>
      </div>
      <div>
        <p className="c-channel-list__type-header">Direct messages</p>
        <button
          disabled={disabled}
          className={
            "c-channel-list-entry " +
            (selectedChannel === 4 ? "c-channel-list-entry--selected" : "")
          }
          onClick={() => onButtonClick(4)}
        >
          <div className="c-channel-list-entry__icon">
            <div className="c-channel-list-entry__icon--online"></div>
          </div>
          sergio
        </button>
        <button
          disabled={disabled}
          className={
            "c-channel-list-entry " +
            (selectedChannel === 5 ? "c-channel-list-entry--selected" : "")
          }
          onClick={() => onButtonClick(5)}
        >
          <div className="c-channel-list-entry__icon">
            <div className="c-channel-list-entry__icon--offline"></div>
          </div>
          @hija:ocsiden.com
        </button>
        <button
          disabled={disabled}
          className={
            "c-channel-list-entry " +
            (selectedChannel === 6 ? "c-channel-list-entry--selected" : "")
          }
          onClick={() => onButtonClick(6)}
        >
          <div className="c-channel-list-entry__icon">
            <div className="c-channel-list-entry__icon--multi-direct">2</div>
          </div>
          ali, sergio
        </button>
      </div>
    </div>
  );
};

export default ChannelList;
