import * as React from "react";
import chatScreen from "../images/another.png";
import SignButtons from "./sign-buttons.js";

const QuotesPanel = ({ pageTitle, children }) => {
  return (
    <main className="c-quotes-container">
      <div className="c-quotes-container__content">
        <h1>About Twadel</h1>
        <h2>
          Our goal is to bring instant messaging to the workplace. We want
          people at work to chat with external partners as easily as sending an
          email but with the fluidity of instant messaging. By adopting an open
          messaging protocol, which eliminates walled gardens between providers,
          we believe we can make this happen.
        </h2>
        {/*}
        <h2>Kevin Jones</h2>
        <p>CEO and Founder, Twadel</p>
  */}
      </div>
    </main>
  );
};

export default QuotesPanel;

/*
<div className="c-chat-container__content__text">
          <h1>Twadel team chat</h1>
          <p>
            Start chatting with teammates, customers and vendors across multiple
            organizations as easily as starting an email conversation.
          </p>
          <SignButtons></SignButtons>
        </div>
        <img
          className="c-chat-container__content__pic"
          src={chatScreen}
          alt="twadel-chat"
        />
        */
