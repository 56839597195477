import * as React from "react";
import { Link } from "gatsby";

const SignButtons = () => {
  return (
    <div className="c-signings">
      <Link
        to="/sign-up"
        className="c-signings__button c-signings__button--sign-up"
      >
        Sign up for free
        {rightArrow()}
      </Link>

      <Link
        to="https://app.twadel.com"
        className="c-signings__button c-signings__button--sign-in"
      >
        Sign in
        {rightArrow()}
      </Link>
    </div>
  );
};

export default SignButtons;

function rightArrow() {
  return (
    <svg
      class="HoverArrow"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      aria-hidden="true"
    >
      <g fill-rule="evenodd">
        <path class="HoverArrow__linePath" d="M0 5h7"></path>
        <path class="HoverArrow__tipPath" d="M1 1l4 4-4 4"></path>
      </g>
    </svg>
  );
}
