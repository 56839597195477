import * as React from "react";
import Header from "../components/header";
import ChatPanel from "../components/chat-panel";
import BulletsPanel from "../components/bullets-panel";
import MatrixPanel from "../components/matrix-panel";
import BusinessCardPanel from "../components/business-card-panel";
import QuotesPanel from "../components/quotes-panel";
import EpiloguePanel from "../components/epilogue-panel";
import Footer from "../components/footer";
import "../sass/styles.scss";

const IndexPage = () => {
  return (
    <div>
      <title>Twadel | Team chat</title>
      <Header pageTitle="Home Page"></Header>
      <ChatPanel></ChatPanel>
      <div
        style={{
          backgroundColor: "rgb(246, 246, 246)",
          transform: "scale(-1,-1)",
          padding: "0",
          margin: "0",
          //backgroundImage:
          //  "linear-gradient(to left, rgb(246, 246, 246),rgb(220, 220, 220))",
        }}
      >
        {makeWave()}
      </div>
      <BusinessCardPanel></BusinessCardPanel>
      <div style={{ backgroundColor: "rgb(246, 246, 246)" }}>{makeWave()}</div>
      <BulletsPanel></BulletsPanel>
      <MatrixPanel></MatrixPanel>
      <QuotesPanel></QuotesPanel>
      <EpiloguePanel></EpiloguePanel>
      {makeWave()}
      <Footer></Footer>
    </div>
  );
};

export default IndexPage;

function makeWave() {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="1366"
      height="32"
      viewBox="0 0 1366 32"
      preserveAspectRatio="none"
      stroke-width="1"
      stroke="#2d4a71"
    >
      <defs></defs>
      <g id="Wave-5">
        <path id="Rectangle" d="M0,0C651,0,715,31.367,1366,31.367V32H0Z"></path>
      </g>
    </svg>
  );
}

function makeWave2() {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="1366"
      height="128"
      viewBox="0 0 1366 128"
      preserveAspectRatio="none"
    >
      <defs></defs>
      <g id="Wave-1">
        <path
          id="Rectangle"
          class="cls-1"
          d="M0,0C623,0,667,151.4614,1366,121.6993V128H0Z"
        ></path>
      </g>
    </svg>
  );
}
