import * as React from "react";
//import GenericLogo from "../images/vector/generic-logo.svg";

const BusinessCard = () => {
  return (
    <div className="c-business-card">
      <div className="c-business-card__content">
        <div className="c-supercorp-text">
          <h1>Monica Smith</h1>
          <h2>Creative Consultant</h2>
          <h3>Matrix ID:</h3>
          <h4>@monica:super-corp.com</h4>
        </div>
        <div className="c-supercorp-logo">
          <div className="c-supercorp-logo__picture">
            <div className="c-supercorp-logo__arm"></div>
            <div className="c-supercorp-logo__arm"></div>
            <div className="c-supercorp-logo__arm"></div>
            <div className="c-supercorp-logo__arm"></div>
          </div>

          <p>SuperCorp</p>
        </div>
      </div>
    </div>
  );
};

export default BusinessCard;
